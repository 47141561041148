.section {
    max-width: 1440px;
    margin: 0 auto;
    padding: 88px 76px;

    @media (max-width: 1380px) {
        padding: 88px 32px;
    }


    @media (max-width: 580px) {
        padding: 88px 16px;
    }
}

.description {
    font-size: 54px;
    line-height: 64px;
    max-width: 598px;
    
    @media (max-width: 580px) {
        font-size: 32px;
        line-height: 42px;
    }
}

.content {
    display: flex;
    justify-content: space-between;
    margin-top: 88px;

    .about {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: rgba(30, 30, 30, 1);
    }

    @media (max-width: 1080px) {
        .about {
            display: none;
        }
    }

    @media (max-width: 580px) {
        margin-top: 0px;
    }
}

.cardsRow {
    display: flex;
}

@media (max-width: 740px) {
    .cardsRow {
        flex-direction: column;
        width: 100%;
    }

    .card {
        margin-top: 88px;
    }
}

.card {
    position: relative;
    .iconRow {
        display: flex;

        .iconContainerFirst {
            width: 59px;
            height: 62px;
            display: flex;
            align-items: center;
            justify-content: center;

            border-top: 1px solid;
            border-right: 4px solid;
            border-bottom: 1px solid;
            border-left: 1px solid;        
            border-color: rgba(200, 200, 200, 1);
            border-radius: 8px;
        }

        .iconContainerTwo {
            width: 56px;
            height: 56px;
            margin-left: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            border: 4px solid rgba(200, 200, 200, 1);
            border-radius: 8px;
        }

        .iconContainerThree {
            width: 64px;
            height: 64px;
            margin-left: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: rgba(200, 200, 200, 1);
            border-radius: 8px;
        }
    }

    .info {
        padding: 0px 16px;
        margin-top: 173px;

        .title {
            font-weight: 400;
            font-size: 30px;
            line-height: 30px;
        }

        .content {
            margin-top: 16px;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            max-width: 257px;
        }
    }
}

.card::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 1px;
    height: calc(100% - 5px);
    background-color: rgba(200, 200, 200, 1);
}

.grayCard::before {
    background-color: rgba(200, 200, 200, 1);
}

.slightlyBlueCard::before {
    background-color: rgba(199, 213, 255, 1);
}

.blueCard::before {
    background-color: rgba(33, 85, 254, 1);
}
