.container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 88px 16px;

    @media (min-width: 768px) {
        padding: 88px 32px;
    }

    @media (min-width: 1440px) {
        padding: 88px 76px 80px 76px;
    }
}

.blueBox {
    background-color: #2155FE;
    border-radius: 24px;
    display: flex;
    width: 100%;
}

.textBlock {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 40px;
    align-items: center;
    width: 100%;

    @media (min-width: 768px) {
        padding: 88px;
        gap: 88px;
    }

    @media (min-width: 1440px) {
        padding: 53px 188px 53px 88px;
        align-items: flex-start;
    }
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.bigText {
    color: #FFFFFF;
    font-weight: 400;
    text-align: left;
    text-wrap: wrap;
    margin: 0;

    font: 32px "Euclid Circular B";
    line-height: 42px;
    text-align: center;

    @media (min-width: 768px) {
        font-size: 54px;
        line-height: 64px;
    }

    @media (min-width: 1440px) {
        text-wrap: wrap;
        max-width: 350px;
        text-align: left;
    }
}

.smallText {
    color: #FFFFFF;
    font: 20px "Euclid Circular B";
    font-weight: 400;

    text-align: center;
    margin: 0;

    line-height: 30px;

    @media (min-width: 768px) {
        font-size: 24px;
        line-height: 34px;
    }

    @media (min-width: 1440px) {
        text-align: left;
    }
}

.urlText {
    margin: 0;
    padding: 0;
    line-height: 30px !important;
    font: 20px "Euclid Circular B";
}

.button {
    text-wrap: nowrap;
    max-width: 280px;
}


.mosaic {
    display: none;

    @media (min-width: 1380px) {
        display: block;
        background-color: rgba(240, 244, 255, 1);
        max-width: 50vw;
        margin-right: 4px;
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
    }
}