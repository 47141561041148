.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 88px 76px;
  display: flex;
  flex-direction: column;
  gap: 88px;

  @media (max-width: 1280px) {
    padding: 88px 32px;
  }

  @media (max-width: 580px) {
    padding: 88px 16px;
  }

}

.title {
  margin: 0;
  font: 54px "Euclid Circular B";
  line-height: 64px;
  font-weight: 400;
}

.filter {
  display: flex;
  gap: 18px;

  @media (max-width: 580px) {
    position: relative;

    .mobileFilters {
      display: block;
      position: absolute;
      top: 58px;
      left: 0px;
      padding: 24px;
      width: calc(100% - 48px);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 8px 40px -8px rgba(33, 85, 254, 0.48);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      label {
        margin-top: 24px;
      }
    }

    .hideMobileFilters {
      display: none;
    }
  }
}

.results {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.pagination {
  margin-top: 40px;
  display: flex;
  gap: 8px;
  justify-content: center;

  @media (min-width: 1380px) {
    margin-top: 0px;
    align-self: center;
  }
}

.backButton {
  border-width: 1px 1px 1px 4px;
  border-style: solid;
  border-color: #1e1e1e;
}

.arrowIcon {
  width: 14px;
  height: 25px;
}

.paginationButton {
  padding: 17px 22.5px 12px 22.5px;
}

.paginationPage {
  font: 30px "TT Interphases Pro Mono Trl";
  line-height: 30px;
  text-align: center;
  font-weight: 400;
  padding: 16px 22px;
  border: 1px solid #1e1e1e;
}

.selected {
  border: 2px solid #2155FE;
}

.filterButton {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-color: rgba(33, 85, 254, 1);
  z-index: 3;
  
  svg {
    margin-left: 10px;
  }
}