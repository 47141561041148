.navBar {
  max-width: 1440px;
  margin: 0 auto;
  height: 96px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 76px;

  @media (max-width: 1380px) {
    padding: 0px 32px;
  }

  @media (max-width: 580px) {
    padding: 0px 16px;
  }

  .navBarStart {

    .logo {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 22px;
      cursor: pointer;
      text-decoration: none;
      color: rgba(30, 30, 30, 1);
    }
  }

  .navBarEnd {
    display: flex;
    align-items: center;

    .link {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      margin-right: 64px;
      text-decoration: none;
      color: rgba(30, 30, 30, 1);
      white-space: nowrap;

      @media (max-width: 1380px) {
        display: none;
      }
    }

    .link:hover {
      color: rgba(33, 85, 254, 1);
    }

    .burgerBtn {
      width: 64px;
      height: 64px;
      padding: 0;
      display: none;

      @media (max-width: 1380px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }

    .actionBtn {
      @media (max-width: 1380px) {
        display: none;
      }
    }
  }
}

.separator {
  width: 30px;
  border: 2px solid rgba(30, 30, 30, 1);
}

.mobile {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;

  display: flex;

  .mobileBar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 344px;
    height: 100vh;
    background-color: white;

    .startElements {
      display: flex;
      justify-content: end;
      padding: 16px 32px;
      flex-direction: column;

      .btnClose {
        display: flex;
        justify-content: end;

        button {
          position: relative;
          width: 64px;
          height: 64px;
          padding: 0;

          .separator {
            position: absolute;
            left: 13px;
            width: 30px;
            border: 2px solid rgba(30, 30, 30, 1);
            background-color: rgba(30, 30, 30, 1);
          }
        }
      }

      .links {
        margin-top: 80px;
        display: flex;
        flex-direction: column;

        a {
          text-align: center;
          font-size: 20px;
          line-height: 30px;
          color: rgba(30, 30, 30, 1);
          text-decoration: none;
        }
      }
    }

    .endElements {
      margin-bottom: 80px;
      padding: 0px 32px;
      display: flex;
      justify-content: center;

      button {
        height: 64px;
        width: 280px;
        padding: 16px 59.5px;
      }


      .divader {
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 1);
      }

      .links {
        display: flex;
        flex-direction: column;
        align-items: center;

        .item {
          padding: 16px 24px;
          font-size: 20px;
          line-height: 30px;
          color: rgba(30, 30, 30, 1);
          white-space: nowrap;
          margin-top: 0 !important;
        }
    
        .item:hover {
          color: rgba(33, 85, 254, 1);
        }
      }


    }

    .links :last-child {
      margin-top: 40px;
    }

    @media (max-width: 580px) {
      width: 100%;
    }
  }

  .mask {
    height: 100vh;
    width: 100%;
    background-color: rgba(33, 85, 254, 0.9);

    @media (max-width: 580px) {
      display: none;
    }
  }
}

.selectContainer {
  position: relative;
  padding: 16px 24px;
  min-width: 238px;
  z-index: 2;

  border-top: 1px solid;
  border-right: 4px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-color: rgba(30, 30, 30, 1);
  border-radius: 8px;

  @media (max-width: 1380px) {
    display: none;
  }



  .label {
    font-size: 20px;
    line-height: 30px;
    color: rgba(30, 30, 30, 1);
  }


  .dropDown {
    position: absolute;
    left: -1px;
    top: 63px;
    width: 100%;
    min-width: 238px;
    background-color: white;


    border-right: 4px solid;
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-color: rgba(30, 30, 30, 1);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .item {
      padding: 16px 24px;
      font-size: 20px;
      line-height: 30px;
      color: rgba(30, 30, 30, 1);
    }

    .item:hover {
      color: rgba(33, 85, 254, 1);
    }
  }

  .icon svg {
    width: 14px;
    height: 14px;
  }

  .iconUp svg {
    transform: rotate(270deg);
  }

  .iconDown svg {
    transform: rotate(90deg);
  }

}

.selectContainer:hover {
  border-color: rgba(33, 85, 254, 1);

  .label {
    color: rgba(33, 85, 254, 1);
  }

  .dropDown {
    border-color: rgba(33, 85, 254, 1);
  }

  svg path {
    stroke: rgba(33, 85, 254, 1);
  }
  
}

.selectContainerActive {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}