.container {
    padding: 40px 16px;

    @media (min-width: 768px) {
        padding: 40px 32px;
    }

    @media (min-width: 1440px) {
        padding: 40px 76px;
    }
}

.divider {
    height: 0px;
    border-top: 1px solid #1E1E1E;
    margin-bottom: 40px;
}

.box {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;


    @media (min-width: 375px) {
        flex-direction: column;
    }

    @media (min-width: 768px) {
        align-items: flex-start;
    }

    @media (min-width: 1440px) {
        flex-direction: row;
        align-items: center;
        gap: 322px;
    }
}

.nameRow {
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 40px;
    font: 14px "Euclid Circular B";
    font-weight: 400;
    line-height: 20px;
    color: #1E1E1E;
    margin-bottom: 0;

    display: flex;
    justify-content: space-between;
    
    @media (max-width: 580px) {
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }
}

.docRow {
    display: flex;

    @media (max-width: 580px) {
        flex-direction: column;
        margin-top: 40px;
    }
}

.doc {
    margin-left: 40px;
    cursor: pointer;
    color: rgba(30, 30, 30, 1);

    @media (max-width: 580px) {
        margin-left: 0;
        margin-top: 20px;
    }
}

.bigName {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    cursor: pointer;
    text-decoration: none;
    color: rgba(30, 30, 30, 1);
}

.nav {
    display: flex;
    align-items: center;
    gap: 24px;

    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
        gap: 64px;
    }
}

.smallUrl {
    font: 20px "TT Interphases Pro Mono Trl";
    line-height: 30px;
    font-weight: 400;
    text-wrap: nowrap;
    text-decoration: none;
    color: #1E1E1E;

    @media (min-width: 1440px) {
        padding-top: 17px;
        padding-bottom: 17px;
    }
}

.smallUrl:hover {
    color: rgba(33, 85, 254, 1);
}

.bigUrl {
    border-width: 1px 4px 1px 1px;
    border-style: solid;
    border-color: #1E1E1E;
    padding: 15px 52px 17px 55px;
    border-radius: 8px;
    font: 20px "TT Interphases Pro Mono Trl";
    line-height: 30px;
    font-weight: 400;
    text-wrap: nowrap;
    text-decoration: none;
    color: #1E1E1E;
}

.smallUrl:hover {
    cursor: pointer;
}

.bigUrl:hover {
    cursor: pointer;
}