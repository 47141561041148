.section {
    padding: 88px 76px 145px;
    display: flex;

    @media (max-width: 1380px) {
        padding: 88px 32px;
    }

    @media (max-width: 580px) {
        padding: 88px 16px;
    }

    @media (max-width: 1130px) {
        flex-direction: column;
    }

    .description {
        font-size: 54px;
        line-height: 64px;
    }

    .about {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        margin-left: 66px;

        @media (max-width: 1130px) {
            margin-top: 88px;
            margin-left: 0;
        }

        .title {
            font-size: 18px;
            line-height: 28px;
        }

        .subtitle {
            margin-top: 24px;
            font-size: 18px;
            line-height: 28px;
            display: flex;
            
            @media (max-width: 1130px) {
                white-space: break-spaces;
            }
        }

        .link {
            font-size: 30px;
            line-height: 30px;
            color: rgba(33, 85, 254, 1);
            text-decoration: underline;
            margin-top: 24px;
            cursor: pointer;
        }
    }
}