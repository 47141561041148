.sectionContainer {
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    padding: 8px 76px;
    height: 656px;

    @media (max-width: 1380px) {
        height: auto;
        padding: 8px 28px;
    }
    
    @media (max-width: 580px) {
        padding: 0px 16px;
    }
}

.content {
    position: absolute;
    top: 130px;
    left: 76px;
    max-width: 715px;

    @media (max-width: 1380px) {
        position: static;
        margin-top: 40px;
        max-width: 100%;
    }

    .sectionTitle {
        font-weight: 400;
        font-size: 54px;
        line-height: 64px;

        @media (max-width: 580px) {
           font-size: 32px;
           line-height: 42px;
        }
    }

    .sectionText {
        margin-top: 32px;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;

        @media (max-width: 580px) {
            font-size: 20px;
            line-height: 30px;
         }
    }
}

.sectionButton {
    position: absolute;
    top: 400px;
    max-width: fit-content;

    @media (max-width: 1380px) {
        margin-top:65px;
        position: static;
    }

    @media (max-width: 580px) {
        margin-top: 40px;

        button {
            width: 100%;
        }
     }
}