.section {
    max-width: 1440px;
    margin: 0 auto;
    padding: 88px 76px;

    @media (max-width: 1380px) {
        padding: 88px 32px;
    }

    @media (max-width: 580px) {
        padding: 88px 16px;
    }

    .description {
        font-size: 54px;
        line-height: 64px;
        max-width: 784px;

        @media (max-width: 580px) {
            font-size: 32px;
            line-height: 42px;
        }
    }

    .content {
        margin-top: 124px;
        display: flex;
        justify-content: space-between;


        .title {
            @media (max-width: 1380px) {
                display: none;
            }
        }

        .slider {
            display: flex;

            .headers {
                .sliderArticle {
                    padding: 24px 0;
                    color: rgba(200, 200, 200, 1);
                    /* border-top: 1px solid rgba(200, 200, 200, 1); */

                    .sliderTitle {
                        margin-top: 24px;
                        font-size: 24px;
                        line-height: 34px;
                        font-weight: 600;
                        cursor: pointer;
                    }

                    .sliderActiveTitle {
                        color: rgba(33, 85, 254, 1);
                    }

                    .sliderText {
                        margin-top: 24px;
                        font-size: 18px;
                        line-height: 28px;
                        color: rgba(30, 30, 30, 1);
                    }

                }

                .sliderArticleActive {
                    /* border-top: 4px solid rgba(155, 178, 251, 1); */
                }

                margin-right: 78px;
                max-width: 354px;
            }

            .image {

            }

            @media (max-width: 1380px) {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .mobileSlider {
        .image {
            margin-top: 88px;
            display: flex;
            justify-content: center;
            
            img {
                width: 100%;
            }
        }

        .desc {
            margin-top: 24px;

            .title {
                font-size: 20px;
                line-height: 30px;
                font-weight: 600;
                color: rgba(33, 85, 254, 1)
            }

            .text {
                margin-top: 16px;
                font-size: 18px;
                line-height: 28px;
            }
        }
    }

}



.divader {
    .defaultDivader {
        width: 100%;
        height: 1px;
        background-color: rgba(200, 200, 200, 1);
    }



    .activeDivaderContainer {
        width: 100%;
        height: 4px;
        background-color: rgba(155, 178, 251, 1);

        .animationDivader {
            background-color: rgba(33, 85, 254, 1);
            height: 4px;
            width: 0%;
        }

        .active {
            transition: width 4s ease-in-out;
            width: 100%;
        }
    }
}


.Root {
	position: relative;
	overflow: hidden;
	background: rgba(155, 178, 251, 1);
	/* border-radius: 99999px; */
	width: 100%;
	height: 4px;

	/* Fix overflow clipping in Safari */
	/* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
	transform: translateZ(0);
}

.Indicator {
	background-color: rgba(33, 85, 254, 1);
	width: 100%;
	height: 100%;
	transition: transform 2s cubic-bezier(0.65, 0, 0.35, 1);
}
