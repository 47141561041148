.sectionContainer {
    max-width: 1440px;
    margin: 0 auto;
    padding: 88px 16px;

    @media (min-width: 768px) {
        padding: 88px 32px;
    }

    @media (min-width: 1440px) {
        padding: 88px 76px;
    }
}

.sectionTitle {
    font: 32px "Euclid Circular B";
    font-weight: 400;
    line-height: 42px;

    @media (min-width: 768px) {
        font-size: 54px;
        line-height: 64px;
    }
}

.descriptionBlock {
    display: flex;
}

.description {
    margin: 0;
    font: 18px "Euclid Circular B";
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    min-width: 432px;
    text-wrap: nowrap;
}
