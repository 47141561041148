.Header {
	all: unset;
	display: flex;
    font-family: 24px "Euclid Circular B";
    font-weight: 600;
    line-height: 34px;
    text-align: left;
    color: #1E1E1E;
}

.Trigger {
	all: unset;
	font-family: inherit;
	background-color: transparent;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 15px;
	line-height: 1;
	color: var(--violet-11);
	box-shadow: 0 1px 0 var(--mauve-6);
	background-color: white;
	&:hover {
		background-color: var(--mauve-2);
	}

	&[data-state="open"] {
		color: #2155FE; 
	}
}

.Content {
	overflow: hidden;
	font-size: 15px;
	color: var(--mauve-11);
	background-color: var(--mauve-2);
	&[data-state="open"] {
		animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
	}
	&[data-state="closed"] {
		animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
	}
}

.ContentText {
	padding: 0;
	padding-top: 32px;

	@media (min-width: 768px) {
		padding-right: 136px;
	}

	@media (min-width: 1440px) {
		padding-right: 288px;
	}

	.Trigger[data-state="open"] > & {
		color: #2155FE; 
	}
}

.Chevron {
	border-width: 1px 4px 1px 1px;
	border-style: solid;
	border-color: #1E1E1E;
	border-radius: 8px;

	padding: 16px 15.5px;
	align-self: flex-start;

	.Trigger[data-state="open"] > & {
		border-color: #2155FE; 
	}

	@media (min-width: 1440px) {
		padding-top: 14px;
		padding-bottom: 14px;
	}
}

.ChevronIcon {
	transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
	stroke: #1E1E1E;

	.Trigger[data-state="open"] > .Chevron > & {
		transform: rotate(180deg);
		stroke: #2155FE;
	}
}

@keyframes slideDown {
	from {
		height: 0;
	}
	to {
		height: var(--radix-accordion-content-height);
	}
}

@keyframes slideUp {
	from {
		height: var(--radix-accordion-content-height);
	}
	to {
		height: 0;
	}
}
