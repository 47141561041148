.label {
  position: absolute;
  margin-left: 16px;
  margin-top: 6px;

  font: 12px "TT Interphases Pro Mono Trl";
  font-weight: 400;
  line-height: 22px;
}

.input {
  background-color: #e6ecff;
  border-radius: 8px;
  border: 0;
  padding: 26px 16px 6px 16px;
  min-width: 308px;
  border: 2px solid rgba(0, 0, 0, 0);

  font: 18px "Euclid Circular B";
  font-weight: 400;
  line-height: 28px;

  &:focus {
    border: 0;
    border: 2px solid #2155fe;
    outline: none;
  }

  &:hover {
    border: 0;
    border: 2px solid #2155fe;
    outline: none;
  }
}

.container {
  width: 100%;
  height: 100%;
}
