.wrapper {
  width: 100%;
}

.squareWrapper {
  border: 1px solid rgba(200, 200, 200, 1);
  border-radius: 9px;
  margin: 4px;
  width: 64px;
  height: 64px;
}

.square {
  z-index: 3;
  width: 64px;
  height: 64px;
  border-radius: 8px;
}

.squareWrapper:hover {
  background-color: rgba(0, 0, 0, 0);
}

.mobileSquare {
  opacity: 0;
}

/* .desctopSquare {
  opacity: 0;
}

.desctopSquare:hover {
  transition: opacity 0.5s;
  opacity: 1;
} */

