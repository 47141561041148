.container {
  padding: 88px 76px;
  background-color: #e6ecff;

  @media (max-width: 900px) {
    padding: 88px 32px;
  }

  @media (max-width: 580px) {
    padding: 88px 16px;
  }
}

.title {
  font: 54px "Euclid Circular B";
  font-weight: 400;
  line-height: 64px;
}

.settings {
  padding: 40px;
  background-color: #ffffff;
  border-radius: 24px;
  margin-top: 88px;
  max-width: 1072px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 580px) {
    padding: 24px;
  }
}

.forms {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;

    .divider {
      height: 1px;
      width: 100%;
      border-top: 1px solid #e6ecff;
      margin: 40px 0;
    }
  }
}

.formBox {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;
}

.formTitle {
  font: 24px "Euclid Circular B";
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  text-align: left;
  margin: 0;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.divider {
  margin: 0 40px;
  border: 0;
  border-left: 1px solid #e6ecff;
  height: 224px;
  align-self: flex-end;
}

.input {
  width: -webkit-fill-available;
  min-width: auto;
}

.policyBox {
  display: flex;
  gap: 16px;
}

.textBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 606px;
}

.policyLinks {
  display: flex;
  gap: 16px;
}

.policyText {
  font: 14px "Euclid Circular B";
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin: 0;
}

.policyLink {
  font: 14px "Euclid Circular B";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
}

.checkbox {
  margin: 0;
  align-self: flex-start;
  margin-top: 2px;
  appearance: none;
  background-color: #fff;
  font: inherit;
  color: currentColor;
  width: 16px;
  height: 16px;
  border: 0.15em solid #2155fe;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #2155fe;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked:before {
    transform: scale(1);
  }
}

.saveButton {
  max-width: 280px;
  border: 0;
}
