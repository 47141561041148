.wrapper {
  margin-top: 88px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1380px) {
    flex-direction: row;
    gap: 152px;
  }
}

.examples {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 580px) {
    flex-direction: row;
  }
}

.button {
  width: 100%;
  font: 18px "Euclid Circular B";
  font-weight: 400;
  line-height: 30px;

  @media (min-width: 1380px) {
    width: unset;
    font-size: 20px;
    padding: 15px 51px 17px 51px;
  }
}

.buttonBox {
  @media (min-width: 580px) {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 1380px) {
    justify-content: flex-start;
  }
}

.market {
  @media (min-width: 1380px) {
    margin-bottom: 24px;
  }
}

.actions {
  @media (min-width: 1380px) {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
}

.text {
  /* margin-top: 88px; */
  margin-bottom: 24px;

  font: 20px "Euclid Circular B";
  font-weight: 400;
  line-height: 30px;
  text-align: center;

  @media (min-width: 580px) {
    font-size: 24px;
    line-height: 34px;
  }

  @media (min-width: 1380px) {
    text-align: left;
  }
}

.pagination {
  margin-top: 40px;
  display: flex;
  gap: 8px;
  justify-content: center;

  @media (min-width: 1380px) {
    margin-top: 0px;
    align-self: flex-start;
  }
}

.backButton {
  border-width: 1px 1px 1px 4px;
  border-style: solid;
  border-color: #1e1e1e;
}

.arrowIcon {
  width: 14px;
  height: 25px;
}

.arrowIcon:hover {
  path {
    stroke: rgba(33, 85, 254, 1);
  }
}



.paginationButton {
  padding: 17px 22.5px 12px 22.5px;
}
