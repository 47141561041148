.Trigger {
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  line-height: 1;
  padding-left: 24px;
  padding-right: 24px;
  height: 64px;
  gap: 5px;
  background-color: white;
  &:focus {
    box-shadow: 0 0 0 2px black;
  }
  border-width: 1px 4px 1px 1px;
  border-style: solid;
  border-color: #c8c8c8;

  font: 20px "TT Interphases Pro Mono Trl";
  font-weight: 400;
  line-height: 30px;
}

.Icon {
  color: Var(--violet-11);
}

.Content {
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  margin-top: 55px;
}

.Viewport {
  padding: 5px;
}

.Item {
  font-size: 13px;
  line-height: 1;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
  &[data-highlighted] {
    outline: none;
  }
  font: 15px "TT Interphases Pro Mono Trl";
  font-weight: 400;
  line-height: 30px;
}

.Label {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.Separator {
  height: 1px;
  margin: 5px;
}

.ItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  cursor: default;
}

.label {
  font: 14px "Euclid Circular B";
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1 1 auto;
  width: 100%;
}

.item {
  font: 20px "TT Interphases Pro Mono Trl";
  font-weight: 400;
  line-height: 30px;
}

.select {
  font: 20px "TT Interphases Pro Mono Trl";
  font-weight: 400;
  line-height: 30px;
}
