.container {
    background-color: #E3EAFF;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
}

.title {
    font-size: 16px;
    font-weight: 600;
}

.text {
    font-size: 16px;
    margin-top: 8px;
}