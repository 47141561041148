.example {
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;

  @media (min-width: 580px) {
    border: 0;
    border-bottom: 1px solid #000000;
  }

  @media (min-width: 1380px) {
    max-width: 424px;
  }

}

.exampleAlbum {
  display: flex;
  flex-direction: column;

  @media (max-width: 580px) {
    .tags {
      border-bottom: 1px solid rgba(33, 85, 254, 1);
      border-left: 1px solid rgba(33, 85, 254, 1);

      .tag {
        border-top: 1px solid rgba(33, 85, 254, 1);
        color: rgba(33, 85, 254, 1);
      }
    }
  }

}

/* .example:hover {
  color: #2155fe;
  border-color: #2155fe;
} */

/* .example:hover .tags {
  border-color: #2155fe;
} */

/* .example:hover .tag {
  border-color: #2155fe;
} */

/* .example:hover .articleUrl {
  color: #2155fe;
} */

/* .example:hover .separator {
  border-color: #2155fe;
} */

/* .example:hover .tryButton {
  color: #2155fe;
  border-color: #2155fe;
} */

.exampleTitle {
  margin: 0;
  margin-bottom: 16px;
  font: 20px "Euclid Circular B";
  font-weight: 600;
  line-height: 30px;
  max-width: 600px;

  @media (min-width: 580px) {
    font-size: 24px;
    line-height: 34px;
  }
}

.exampleBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 24px;
}

.exampleBlockAlbum {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding-top: 24px;
  padding-bottom: 24px;

  @media (max-width: 1280px) {
    justify-content: space-between;

    .titleBlockAlbum {
      min-width: auto;
      flex: 2;
    }

    .counters {
      flex-direction: column;
      flex: 1;
    }

    .counterAlbum {
      border-top: 1px solid rgba(0, 0, 0, 1);
    }

  }

  @media (max-width: 580px) {
    padding: 24px 24px 0px 24px;
    flex-direction: column;
    border-left: 1px solid rgba(33, 85, 254, 1);
    
    .counterAlbum {
      border-top: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .counterValue {
        margin-top: 0;
      }
    }
  }

}

.tags {
  display: flex;
  border-bottom: 1px solid #1e1e1e;
}

.tag {
  padding: 3px 15px 5px 16px;
  margin: 0;
  border-right: 1px solid #1e1e1e;
  font: 14px "Euclid Circular B";
  font-weight: 400;
  line-height: 20px;

  @media (min-width: 580px) {
    border-top: 1px solid #1e1e1e;
  }
}

.tag:first-child {
  @media (min-width: 580px) {
    border-left: 1px solid #1e1e1e;
  }
}

.articleUrl {
  margin-bottom: 66px;
  font: 20px "Euclid Circular B";
  font-weight: 400;
  line-height: 30px;
  color: #1e1e1e;
}

.articleUrl:hover {
  color: #2155fe !important;
}

.articleUrl:visited {
  color: #1e1e1e;
}

.counterAlbum {
  padding-top: 12px;
  padding-bottom: 12px;
}

.counters {
  display: flex;
}

.separator {
  margin: 0;
  margin-left: 24px;
  margin-right: 24px;
  transform: rotate(180deg);
  border: 0;
  border-left: 1px solid #1e1e1e;
}

.counterText {
  font: 14px "Euclid Circular B";
  line-height: 20px;
  font-weight: 400;
  margin: 0;
  text-wrap: balance;
}

.counterValue {
  font: 30px "TT Interphases Pro Mono Trl";
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  margin-top: 24px;
}

.tryButton {
  margin-top: 40px;
  padding: 15px 24px 17px 24px;
  font: 18px "Euclid Circular B";
  line-height: 30px;
  font-weight: 400;

  @media (min-width: 580px) {
    font-size: 20px;
    align-self: flex-start;
    padding: 15px 15.5px 17px 15.5px;
    text-wrap: nowrap;
  }
}

.buyBlock {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  gap: 24px;
  min-width: 208px;
}

.price {
  margin: 0;
  font: 30px "TT Interphases Pro Mono Trl";
  font-weight: 400;
  line-height: 30px;
  color: #2155fe;
}

/* .exampleAlbum:hover {
  color: #2155fe;
  border-color: #2155fe;
}

.exampleAlbum:hover .tags {
  border-color: #2155fe;
}

.exampleAlbum:hover .tag {
  border-color: #2155fe;
}

.exampleAlbum:hover .articleUrl {
  color: #2155fe;
}

.exampleAlbum:hover .separator {
  border-color: #2155fe;
} */

.titleBlockAlbum {
  max-width: 600px;
}

.endPart {
  display: flex;

  @media (max-width: 580px) {
    flex-direction: column;

    .buyBlock {
      margin-left: 0;
    }
  }
}

.collapse {
  margin-top: 16px;
  cursor: pointer;
  text-align: right;
  font-size: 12px;
  font-weight: 600;
  color: rgba(33, 85, 254, 1);
}