.section {
    padding: 88px 76px;
    background-color: rgba(230, 236, 255, 1);

    @media (max-width: 970px) {
        padding: 88px 32px;
    }

    @media (max-width: 400px) {
        padding: 88px 16px;
    }

    .sectionTitle {
        font-size: 54px;
        line-height: 64px;
    }

    .content {
        display: flex;
        justify-content: space-between;
        margin-top: 88px;

        .wrapper {
            max-width: 640px;
            background-color: white;
            border-radius: 24px;
            padding: 24px;
            height: 452px;
            flex: 1;

            .wrapperTitle {
                font-size: 24px;
                line-height: 34px;
                font-weight: 600;
                color: rgba(30, 30, 30, 1)
            }

            .wrapperContent {
                button {
                    margin-top: 24px;
                    width: 280px;
                }
            }


            @media (max-width: 970px) {
                max-width: 100%;
            }
        }

        .row {
            padding-top: 24px;
            display: flex;
            border-top: 1px solid rgba(230, 236, 255, 1);
            margin-top: 24px;

            .value {
                margin-left: 20px;
            }

        }

        .metric {
            margin-left: 8px;

            .description {
                font-size: 16px;
                line-height: 20px;
                margin-top: 24px;
            }

            .inputContainer {
                margin-top: 40px;
                position: relative;
                display: flex;

                .label {
                    position: absolute;
                    top: 6px;
                    left: 16px;
                }

                .input {
                    width: calc(100% - 8px);
                    border: none;
                    border-radius: 8px;
                    background-color: rgba(230, 236, 255, 1);
                    padding: 41px 16px 8px;
                    flex: 1;
                }

                .input:focus {
                    outline: none;
                }
            }

            .line {
                width: 100%;
                border: 1px solid rgba(230, 236, 255, 1);
                margin-top: 40px;
            }

            .total {
                display: flex;
                margin-top: 24px;
                .text {
                    font-size: 20px;
                    line-height: 30px;
                    color: rgba(30, 30, 30, 1);
                }

                .cost {
                    font-size: 30px;
                    line-height: 30px;
                    color: rgba(33, 85, 254, 1);
                    margin-left: 40px;
                }
            }

            button {
                margin-top: 24px;
                width: 280px;
            }

        }


        @media (max-width: 970px) {
            flex-direction: column;

            .metric {
                margin-left: 0;
                margin-top: 8px;

                button {
                    max-width: 280px;
                    width: -webkit-fill-available;
                }
            }

        }


    }

}