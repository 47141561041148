.container {
  padding: 88px 76px;
  background-color: #e6ecff;
  display: flex;
  flex-direction: column;
  gap: 88px;


  @media (max-width: 1380px) {
    padding: 40px 32px;
    gap: 40px;
  }

  @media (max-width: 768px) {
    padding: 40px 16px;
    gap: 16px;
  }
}

.title {
  font: 54px "Euclid Circular B";
  font-weight: 400;
  line-height: 64px;
}

.pagination {
  margin-top: 40px;
  display: flex;
  gap: 8px;
  justify-content: center;

  @media (min-width: 1380px) {
    margin-top: 0px;
    align-self: center;
  }
}

.backButton {
  border-width: 1px 1px 1px 4px;
  border-style: solid;
  border-color: #1e1e1e;
}

.arrowIcon {
  width: 14px;
  height: 25px;
}

.paginationButton {
  padding: 17px 22.5px 12px 22.5px;
}

.paginationPage {
  font: 30px "TT Interphases Pro Mono Trl";
  line-height: 30px;
  text-align: center;
  font-weight: 400;
  padding: 16px 22px;
  border: 1px solid #1e1e1e;
}

.selected {
  color: #2155fe;
  border: 4px solid #2155fe;
}

.table {
  background-color: #ffffff;
  border-radius: 24px;
  border-collapse: collapse;
}

.tableHead {
  border-bottom: 4px solid #c7d5ff;
}

.tableCol {
  padding-top: 21px;
  padding-bottom: 21px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: left;
  vertical-align: top;
  font: 12px "TT Interphases Pro Mono Trl";
  font-weight: 400;
  line-height: 22px;
  text-wrap: wrap;
}

.tableCol:first-child {
  padding-left: 24px;
}

.tableCol:last-child {
  padding-right: 24px;
}

.tableCell {
  padding-top: 23px;
  padding-bottom: 23px;
  font: 15px "Euclid Circular B";
  font-weight: 400;
  line-height: 28px;
  color: #2155fe;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
}

.numCell {
  padding-top: 25px;
  padding-bottom: 25px;
  font: 15px "Euclid Circular B";
  font-weight: 400;
  line-height: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

.firstCell {
  text-align: start;
  padding-left: 24px;
}

.lastCell {
  padding-right: 24px;
}

.tableRow {
  border-top: 1px solid #c7d5ff;
  height: 76px;
}

.tableRow:hover {
  cursor: pointer;
  background-color: rgba(230, 236, 255, 1);
}

.name {
  min-width: 280px;
  max-width: 300px;
}

.num {
  min-width: 80px;
}

.metric {
  min-width: 200px;
  max-width: 220px;
}

.statusBox {
  display: flex;
  gap: 7px;
  align-items: center;
}

.statusBox > p {
  margin: 0;
}

.statusCell {
  padding-top: 25px;
  padding-bottom: 25px;
  font: 12px "TT Interphases Pro Mono Trl";
  font-weight: 400;
  line-height: 22px;
  padding-right: 20px;
  padding-left: 20px;
}



.history {
  background-color: white;
  border-radius: 24px;
}

.historyAccordion {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;

  .accordionTitles {
    display: flex;
  }

  .cellTitle {
    font-size: 12px;
    line-height: 22px;
  }

  .cellValue {
    font-size: 12px;
    line-height: 22px;
  }

  svg {
    width: 14px;
    height: 14px;
  }
}

.accordionDivader {
  border-top: 4px solid #e6ecff;;
}

.opened {
  transform:rotate(270deg);

  path {
    stroke: rgba(33, 85, 254, 1);
  }
}

.closed {
  transform:rotate(90deg)
}

.accordionContent {
  .row {
    padding: 16px 24px;
    display: flex;

    .cell {
      font-size: 12px;
      line-height: 22px;
    }
  }

  .divader {
    height: 1px;
    background-color: rgba(230, 236, 255, 1);
    margin: 0 24px;
  }
}

.input {
  display: flex;
}

.iconSend {
  display: flex;
  margin-left: 12px;
  height: 16px;
  cursor: pointer;
}