.authRoot {
  z-index: 1000;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.authBackground {
  background-color: #e6ecfff0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  position: absolute;
  top: 0%;
}

.authContainer {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background-color: #ffffff;
  border-radius: 24px;
}

.authBigText {
  font: 24px "Euclid Circular B";
  font-weight: 600;
  line-height: 34px;
  margin: 0;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.linkButton {
  font: 16px "TT Interphases Pro Mono Trl";
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2155fe;
  cursor: pointer;
  margin: 0;
}

.noAccount {
  font: 16px "TT Interphases Pro Mono Trl";
  font-weight: 400;
  line-height: 30px;
  margin: 0;
}

.promptRegister {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.error {
  color: red;
  font-size: 12px;
}