.wrapper {
  padding: 16px 76px;

  @media (max-width: 1380px) {
    padding: 16px 32px;
  }

  @media (max-width: 580px) {
    padding: 16px 16px;
  }
}

.title {
    font-weight: 600;
}

.form {
    margin: 0 auto;
    width: fit-content;
    max-width: 345px;
    padding: 16px;
    border: 1px solid #1E1E1E;
    border-radius: 8px;

    button {
        width: 100%;
    }
}

.fields {
    margin: 16px 0;

    div:last-child {
        margin-top: 20px;
    }
}

.formError {
  margin: 16px 0;
  color: red;
}