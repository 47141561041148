.button {
    padding: 16px 56px;
    border-top: 1px solid;
    border-right: 4px solid;
    border-bottom: 1px solid;
    border-left: 1px solid;  
    border-radius: 8px;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
    
    @media (min-width: 1440px) {
        text-wrap: nowrap;
    }
}

.blackVariant {
    color: rgba(30, 30, 30, 1);
    background-color: white;
    border-color: rgba(30, 30, 30, 1);
}

.blackVariant:hover {
    border-color: rgba(33, 85, 254, 1);
    color: rgba(33, 85, 254, 1);
}

.blueVariant {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(33, 85, 254, 1);
    border-color: rgba(155, 178, 251, 1);
}

.blueVariant:hover {
    background-color: rgba(20, 65, 213, 1);
}


.whiteVariant {
    color: rgba(33, 85, 254, 1);
    background-color: rgba(255, 255, 255, 1);
    border-color: rgba(155, 178, 251, 1);
}

.whiteVariant:hover {
    background-color: rgba(199, 213, 255, 1);
}