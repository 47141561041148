.root {
	border-radius: 6px;
	background-color: var(--mauve-6);
	box-shadow: 0 2px 10px var(--black-a4);
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.item {
	overflow: hidden;
	margin-top: 1px;



	&:first-child {
		margin-top: 0;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	&:last-child {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	&:focus-within {
		position: relative;
		z-index: 1;
		box-shadow: 0 0 0 2px var(--mauve-12);
	}
}

.trigger {
	font: 20px "Euclid Circular B";
	line-height: 30px;
	font-weight: 600;
	text-wrap: balance;

	justify-content: space-between;

	& > p {
		margin: 0;
	}

	@media (min-width: 1440px) {
		gap: 152px;
		font-size: 24px;
		line-height: 34px;
		text-wrap: auto;
	}
}

.description {
	font: 18px "Euclid Circular B";
	font-weight: 400;
	line-height: 28px;
	color: #1E1E1E
}

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 88px;

	@media (min-width: 1440px) {
		flex-direction: row;
		justify-content: space-between;
		gap: 322px;
	}
}

.divider {
	margin-top: 40px;
	height: 0px;
	border: 0;
	border-bottom: 1px solid #C8C8C8;
}