.modalRoot {
    z-index: 1000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .modalBackground {
    background-color: #e6ecfff0;
    width: 100vw;
    height: 100vh;
    z-index: 500;
    position: absolute;
    top: 0%;
  }
  
  .modalContainer {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    background-color: #ffffff;
    border-radius: 24px;
    max-width: 424px;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
  }

  .desc {
    text-align: center;
  }

  .actions {
    button:last-child {
        margin-top: 16px;
    }

    button {
        width: 100%;
    }

  }


  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }